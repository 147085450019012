import api from '@/services/api';

export const getCompanyFaculties = async (companyId) => {
    const result = await api.get(`/faculty/company/${companyId}`);
    return result.data;
};

export const getFacultyDepartments = async (facultyId) => {
    const result = await api.get(`/faculty/departments/${facultyId}`);
    return result.data;
};

export const createFaculty = async (facultyToCreate) => {
    const result = await api.post(`/faculty`, facultyToCreate);
    return result.data;
};

export const createFacultyDepartment = async (facultyDepartmentToCreate) => {
    const result = await api.post(`/faculty/department`, facultyDepartmentToCreate);
    return result.data;
};

export const updateFaculty = async (facultyToUpdate) => {
    const result = await api.patch(`/faculty`, facultyToUpdate);
    return result.data;
};

export const deleteFaculty = async (facultyId) => {
    const result = await api.delete(`/faculty/${facultyId}`);
    return result.data;
};

export const deleteFacultyDepartment = async (facultyDepartmentId) => {
    const result = await api.delete(`/faculty/department/${facultyDepartmentId}`);
    return result.data;
};

export const getStudentData = async sidNumber => {
    const result = await api.get(`/students/${sidNumber}/all`);
    return result.data;
};

export const openPort = async student => {
    const result = await api.post(`/students/open`, student);
    return result.data;
  };
