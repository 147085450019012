import store from '@/store';
var permissions = JSON.parse(store.state.oidcStore.user.permissions) || [];

export const getAllUserPermissions = () => { 
    return permissions
}

export const userHasPermission = (scope, permission, companyId) => {
    return permissions
        .find(p => p.Scope == scope && p.Permission == permission && p.CompanyId == companyId) ? true : false;
}

export const getCompanyIdsWhereUserHasPermission = (scope, permission) => {
    permissions
        .find(p => p.Scope == scope && p.Permission == permission)
        .map(p => p.CompanyId)
}