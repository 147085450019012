<template>
  <div v-loading="loading">
    <!-- Section for editing user profile information -->
    <ui-header :heading="$t('labels.profile.my_profile')"></ui-header>
    <ui-card
      :heading="`${$t('actions.edit')} ${$t('profile.profile')}`"
      class="mb-8"
    >
      <ui-form
        v-if="profile"
        @submit="handleSubmit"
        :submitDisabled="!validFirst || !validLast"
        :submitText="$t('actions.save')"
        hideCancel
      >
        <ui-form-field :label="$t('fields.first_name')">
          <el-badge class="badge-100" :hidden="validFirst" is-dot>
            <el-input type="text" v-model="profile.firstName"></el-input>
          </el-badge>
        </ui-form-field>

        <ui-form-field :label="$t('fields.last_name')">
          <el-badge class="badge-100" :hidden="validLast" is-dot>
            <el-input type="text" v-model="profile.lastName"></el-input>
          </el-badge>
        </ui-form-field>

        <ui-form-field :label="$t('fields.email')">
          <el-input
            type="text"
            v-model="profile.email"
            :disabled="true"
          ></el-input>
        </ui-form-field>

        <ui-form-field :label="$t('fields.phone')">
          <el-badge class="badge-100" :hidden="validPhone">
            <el-input type="text" v-model="profile.phone"></el-input>
          </el-badge>
        </ui-form-field>
      </ui-form>
    </ui-card>

    <!-- Section for changing the user building connections -->
    <ui-card
      v-if="possibleDepartmentUserConnections"
      :heading="`${$t('actions.change')} ${$tc('models.department', 2)}`"
    >
      <ui-link
        type="danger"
        @click="openConnectionsModal"
        v-if="possibleDepartmentUserConnections"
      >
        {{ `${$t("actions.change")} ${$tc("models.department", 2)}` }}
      </ui-link>
    </ui-card>

    <connection-modal
      :show="connectionsModal"
      :text="$t('actions.confirm_delete', { model: $tc('misc.connections') })"
      :confirmText="$t('actions.change')"
      :companies="possibleDepartmentUserConnections"
      :permissionValidator="userHasPermission()"
      @confirm="changeConnections"
      @cancel="connectionsModal = false"
    >
    </connection-modal>
  </div>
</template>

<script>
import UiLink from "@/components/ui/UiLink";
import UiCard from "@/components/ui/UiCard";
import UiForm from "@/components/ui/UiForm";
import UiHeader from "@/components/ui/UiHeader";
import UiFormField from "@/components/ui/UiFormField";
import ConnectionModal from "../components/ConnectionModal";
import { mapGetters } from "vuex";
import {
  getProfile,
  updateProfile,
  updateUserDepartmentConnections,
  getPossibleDepartmentUserConnections,
} from "../api";
import { userHasPermission } from "@/services/permissions";

export default {
  data() {
    return {
      profile: null,
      loading: false,
      forced: false,
      connectionsModal: false,
      possibleDepartmentUserConnections: null,
    };
  },

  components: {
    UiLink,
    UiCard,
    UiHeader,
    UiForm,
    UiFormField,
    ConnectionModal,
  },

  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),

    validFirst() {
      if (this.profile.firstName) {
        return this.profile.firstName !== "";
      }
      return false;
    },

    validLast() {
      if (this.profile.lastName) {
        return this.profile.lastName !== "";
      }
      return false;
    },

    validPhone() {
      if (this.profile.phone) {
        return (
          this.profile.phone !== "" &&
          this.profile.phone.length >= 8 &&
          this.profile.phone.length <= 12
        );
      }
      return false;
    },
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const profile = await updateProfile(this.profile);
        this.profile = profile;
        this.$router.push({ name: "my-courses-index" });
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t("errors.general"), type: "error" });
        this.loading = false;
      }
    },

    userHasPermission() {
      return userHasPermission;
    },

    async getProfile() {
      this.loading = true;
      try {
        const profile = await getProfile();
        this.profile = profile;
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t("errors.general"), type: "error" });
        this.loading = false;
      }
    },

    async getPossibleDepartmentUserConnections() {
      getPossibleDepartmentUserConnections()
        .then((data) => {
          // if no data is sent then user does not have permissions or is not under any company
          if (data.length) {
            this.possibleDepartmentUserConnections = data;
            if (this.$router.history.current.params.new_user == true) {
              this.connectionsModal = true;
            }
          }
        })
        .catch((e) => {
          this.$message({ message: this.$t("errors.general"), type: "error" });
        });
    },

    async openConnectionsModal() {
      this.connectionsModal = true;
    },

    async changeConnections(selectedDepartmentIds) {
      try {
        await updateUserDepartmentConnections(selectedDepartmentIds);
        document.cookie =
          "new_user=; Max-Age=0; expires=Thu, 18 Dec 1971 12:00:00 UTC; domain=.tiv.no";

        this.$message({
          message: this.$t("connections.change"),
          type: "success",
        });
        this.connectionsModal = false;
        this.$router.push({ name: "my-courses-index" });
      } catch (e) {
        this.$message({ message: this.$t("errors.general"), type: "error" });
      }
    },

    deleteNewUserCookie() {
      this.removeItem("registered");
    },

    removeItem(sKey, sPath, sDomain) {
      document.cookie =
        encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
    },
  },

  created() {
    const params = this.$router.history.current.params;
    if (params && params.forced) {
      this.$message({
        message: this.$t("errors.need_personal_info"),
        duration: 5000,
      });
    }

    this.getProfile();
    this.getPossibleDepartmentUserConnections();
  },
};
</script>
<style scoped>
.badge-100 {
  width: 100%;
}
</style>
