import api from '@/services/api';

export const getProfile = async () => {
  const result = await api.get(`/profiles/`);
  return result.data;
};

export const updateProfile = async payload => {
  const result = await api.patch(`/profiles/`, payload);
  return result.data;
};

export const getPossibleDepartmentUserConnections = async () => {
  const result = await api.get(`/departments/possibleConnections`);
  return result.data;
}

export const updateUserDepartmentConnections = async (departments) => {
  const result = await api.patch(`/users/departments`, departments);
  return result.data;
}

export const getBuildingFaculties = async (buildingId) => {
  const result = await api.get(`/faculty/building/${buildingId}`);
  return result.data;
}
