<template>
  <transition name="fade">
    <div v-if="show" class="modal-container">
      <div class="w-full max-w-2xl">
        <div class="bg-white rounded overflow-hidden">
          <!-- Heading -->
          <div class="p-4 md:p-6 font-bold uppercase">
            {{`${$t('actions.choose')} ${$tc('models.department', 2)}`}}
          </div>

          <!-- Main Content -->
          <div class="px-4 md:px-6">
            <div class="flex flex-row">
              <!-- Company selector -->
              <el-select v-model="selectedCompany" :placeholder="`${$t('actions.select')} ${$tc('models.company', 1)}`">
                <el-option
                    v-for="item in companies"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId">
                </el-option>
              </el-select>

              <!-- Building selector -->
              <el-select v-show="selectedCompany" v-model="selectedBuilding" :placeholder="`${$t('actions.select')} ${$tc('models.building', 1)}`" class="ml-2">
                <el-option
                    v-for="item in buildings"
                    :key="item.buildingId"
                    :label="item.buildingName"
                    :value="item.buildingId">
                </el-option>
              </el-select>

              <!-- Faculty selector (only for UiB users) -->
              <el-select v-if="permissionValidator('faculty', 'view', this.selectedCompany)" v-show="selectedBuilding" v-model="selectedFaculty" :placeholder="`${$t('actions.select')} ${$tc('models.faculty', 1)}`" class="ml-2">
                <el-option
                    v-for="item in faculties"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>

            <!-- Department "Card" Picker -->
            <div v-if="selectedBuilding" class="my-4 -mx-2 flex flex-row flex-wrap height-constraint">
              <div class="p-2" v-for="(department, index) in departments" :key="department.departmentId">
                <el-checkbox v-model="departments[index].connected" :label="department.departmentName" border></el-checkbox>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="p-4 md:p-6 bg-gray-100">
            <div class="flex justify-end">
              <ui-link type="text" class="ml-4" @click="handleCancel">
                {{cancelText}}
              </ui-link>
              <ui-link type="danger" @click="handleConfirm">
                {{confirmText}}
              </ui-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import {getBuildingFaculties, getPossibleDepartmentUserConnections} from '../api';
import {getFacultyDepartments} from "../../admin/api";

export default {
  name: "ConnectionModal",
  components: {
    UiLink,
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    confirmText: {
      type: String,
      default: function() {
        return this.$t('actions.confirm');
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    },
    companies: {
      required: true
    },
    permissionValidator: {
      required: false,
      default: (a,b,c) => null
    }
  },

  data() {
    return {
      selectedCompany: null,
      selectedBuilding: null,
      selectedFaculty: null,
      /// Format:
      /// department: { id: 123, checked: true }
      buildings: [],
      faculties: [],
      departments: [],
      originalDepartments: [],

      // MISC
      companySelected: false,
      connections: [],
    }
  },

  computed: {
    hasPermission: () => {
      return this.permissionValidator('faculty', 'view', this.selectedCompany) || false;
    }
  },

  watch: {
    selectedCompany: function() {
      if (this.selectedCompany !== null) {
        const company = this.companies.find(x => x.companyId === this.selectedCompany);
        this.buildings = company.buildingConnections;
        this.selectedBuilding = null;
      }
    },

    selectedBuilding: async function() {
      if (this.selectedBuilding !== null) {
        const building = this.buildings.find(x => x.buildingId === this.selectedBuilding);
        this.departments = building.departmentConnections;
        this.originalDepartments = building.departmentConnections;

        // If user has the "UiB" permission get the faculties
        if (this.permissionValidator('faculty', 'view', this.selectedCompany)) {
          this.selectedFaculty = null;
          this.faculties = await getBuildingFaculties(this.selectedBuilding);
        }
      }
    },

    selectedFaculty: async function() {
      if (this.permissionValidator('faculty', 'view', this.selectedCompany) && this.selectedFaculty) {
        const facultyDepartments = await getFacultyDepartments(this.selectedFaculty);

        if (facultyDepartments !== null && facultyDepartments.length > 0) {
          // Filter the current departments with the ones fetched from faculty
          let filteredDepartments = [];
          facultyDepartments.forEach(department => {
            this.originalDepartments.forEach(originalDepartment => {
              if (department.departmentId === originalDepartment.departmentId) {
                filteredDepartments.push(originalDepartment);
              }
            })
          })
          this.departments = filteredDepartments;
        } else {
          this.departments = [];
        }
      }
    }
  },

  methods: {
    handleConfirm() {
      const filteredDepartments = this.filterSelectedDepartments();
      this.$emit('confirm', filteredDepartments);
    },

    handleCancel() {
      this.$emit('cancel');
    },

    filterSelectedDepartments() {
      let selectedDepartment = [];
      this.companies.forEach(company => {
        company.buildingConnections.forEach(building => {
          building.departmentConnections.forEach(department => {
            selectedDepartment.push({
                departmentId: department.departmentId,
                isChecked: department.connected
              });
          })
        })
      });
      return selectedDepartment
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Department Card */

.department-card {
  border: 2px solid red;
  background-color: white;
  color: red;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  transition: transform 0.2s; /* Animation */
}

.department-card:hover {
  border-color: #9d0000;
  background-color: red;
  color: white;
  transform: scale(1.05);
  cursor: pointer;
}

/* TEMP */

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.height-constraint {
  max-height: 560px;
  overflow-y: auto;
}
</style>
